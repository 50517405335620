import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import MDXComponents from 'src/components/mdx-components';
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Button } from 'src/components/atoms/Button';
import { InlineList } from 'src/components/molecules/InlineList';
import { FluidImg, FixedImg } from 'src/embeds/image/image-dato';

export default function Drawer({ heading, content, contentNode, callouts, ctas, customData }) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  const drawerCustomData = JSON.parse(customData);
  return (
    <MDXProvider components={MDXComponents}>
      <Section {...drawerCustomData?.flags}>
        <SectionHeader centered={drawerCustomData?.flags?.centered}>
          {!!heading && <h2>{heading}</h2>}
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </SectionHeader>

        {callouts.length > 0 ? (
          <Grid spacious {...drawerCustomData?.gridProps}>
            {callouts.map(({
              heading,
              content,
              contentNode,
              media,
              customData,
              preHeading,
              preHeadingImage
            }, i) => {
              customData = JSON.parse(customData);
              return (
                <Column {...customData?.columnProps} key={i}>
                  {media && !drawerCustomData?.imgProps?.fixed
                    ? <FluidImg
                        css={`
                          margin-bottom: 20px;
                        `}
                        data={media}
                        width={media.width}
                        role="presentation"
                        maxWidth="100%"
                        constrain
                      />
                    : media && drawerCustomData?.imgProps?.fixed
                    ? <FixedImg
                        css={`
                          margin-bottom: 20px;
                          margin-left: auto;
                          margin-right: auto;
                        `}
                        data={media}
                        width={drawerCustomData?.imgProps?.width || media.width}
                        role="presentation"
                        maxWidth="100%"
                        constrain
                      />
                    : null
                  }
                  {preHeading && (
                    <div style={{
                      display: 'flex',
                      marginBottom: '1rem',
                      alignItems: 'center'
                    }}>
                      {preHeadingImage && <FixedImg
                        data={preHeadingImage}
                        width={preHeadingImage.width}
                        role="presentation"
                        maxWidth="100%"
                        style={{
                          marginRight: '0.5rem'
                        }}
                        constrain
                      />}
                      <div className="f-mono"><small>{preHeading}</small></div>
                    </div>
                  )}
                  {heading && <h3>{heading}</h3>}
                  {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
                </Column>
              );
            })}
          </Grid>
        ) : null}
        {ctas.length > 0 && (
          <InlineList centered style={{ width: '100%', paddingTop: 80 }}>
            {ctas.map((cta, i) => {
              const ctaFlags = i > 0 ? { secondary: true } : {};
              return (
                <li key={i}>
                  <Button to={cta.url} {...ctaFlags}>
                    {cta.text}
                  </Button>
                </li>
              );
            })}
          </InlineList>
        )}
      </Section>
    </MDXProvider>
  );
}
